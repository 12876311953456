import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const ThumbCard = styled.button`
	color: ${getColor('white')};
	opacity: 20%;
	transition: opacity 400ms ${(prop) => prop.theme.ease};
	text-align: left;
	position: relative;
	z-index: 5;

	&:hover {
		opacity: 1;
	}

	h3 {
		font-weight: 700;
		margin-bottom: 12px;

		@media ${breakpoint('tablet')} {
			margin-bottom: 24px;
		}
	}

	p {
		display: block;
	}
`

const ProgressBar = styled.div`
	margin-bottom: 32px;
	height: 1px;
	width: 100%auto;
	background: ${getColor('white')};
	position: relative;

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 3px;
		position: absolute;
		top: 50%;
		left: 0;
		background: ${getColor('white')};
		transform: translate(0, -50%);
		max-width: 0;
		transition: opacity 200ms linear;
	}
`

export const ShowcaseThumb = ({ copy, title }) => {
	return (
		<ThumbCard>
			<h3 className="featured-text">{title}</h3>

			<ProgressBar className="progress-bar"></ProgressBar>

			<p data-copy={copy}>{copy}</p>
		</ThumbCard>
	)
}

ShowcaseThumb.propTypes = {
	title: PropTypes.string,
	copy: PropTypes.string,
}

ShowcaseThumb.defaultProps = {
	title: `Lorem Ipsum`,
	copy: `Chloe Szep is one of Australia's most prominent influencers and her limited-edition drops often sell out within the same day.`,
}

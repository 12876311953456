import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'

const SlideCard = styled.div`
	width: 100%;
	position: relative;
	display: block;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-top: 130%;

		@media ${breakpoint('tablet')} {
			padding-top: 66.66%;
		}

		@media ${breakpoint('laptop')} {
			padding-top: 40%;
		}
	}
`

export const ShowcaseSlide = ({ title, image }) => {
	const { mediaItemUrl } = image

	return (
		<SlideCard>
			{mediaItemUrl && (
				<img src={mediaItemUrl} alt={title} className="bg-cover" />
			)}
		</SlideCard>
	)
}

ShowcaseSlide.propTypes = {
	title: PropTypes.string,
	image: PropTypes.object,
}

ShowcaseSlide.defaultProps = {
	title: 'Lorem Ipsum',
	image: {},
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { breakpoint } from '../../shared/theme'
import { HomeSectionHeader } from '../homeSectionHeader'
import { PartnerScroller } from '../partnershipScroller'
import SectionTransition from '../../elements/activeSectionDefault'

const PartnershipsWrapper = styled(SectionTransition)`
	padding-top: 40px;
	margin-bottom: 40px;

	@media (${breakpoint('tablet')}) {
		margin-bottom: 80px;
	}

	> div:first-child {
		margin-bottom: 48px;

		@media (${breakpoint('tablet')}) {
			margin-bottom: 180px;
		}

		@media (${breakpoint('desktop')}) {
			margin-bottom: 220px;
		}
	}
`

export const Partnerships = ({ isActive, gallery, subheading, hide }) => {
	if (!gallery) {
		return null
	}
	if (hide) {
		return null
	}

	return (
		
		<PartnershipsWrapper isActive={isActive} >
			<HomeSectionHeader link={subheading} isActive={isActive} />
			<PartnerScroller partnerships={gallery} link={subheading} />
		</PartnershipsWrapper>
	)
}

Partnerships.propTypes = {
	isActive: PropTypes.bool,
	gallery: PropTypes.array,
}

Partnerships.defaultProps = {
	isActive: PropTypes.false,
	gallery: [],
}

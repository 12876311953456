import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const VideoWrapper = styled.video`
	z-index: -1;
`

const VideoPlayer = ({ video, image, autoplay }) => {
	if (!video) {
		return null
	}

	const desktopMp4 = video.desktopMp4 != null ? video.desktopMp4 : ''

	const desktopWebm = video.desktopWebm != null ? video.desktopWebm : ''

	const mobileMp4 = video.mobileMp4 != null ? video.mobileMp4 : desktopMp4

	const mobileWebm = video.mobileWebm != null ? video.mobileWebm : desktopWebm

	return (
		<>
			<VideoWrapper
				className="bg-cover mobile-hide"
				autoPlay={autoplay}
				muted
				loop
				poster={image ? image.mediaItemUrl : ''}
				playsInline
			>
				{desktopMp4 && (
					<source src={desktopMp4.mediaItemUrl} type="video/mp4"></source>
				)}
				{desktopWebm && (
					<source src={desktopMp4.mediaItemUrl} type="video/webm"></source>
				)}
			</VideoWrapper>

			<VideoWrapper
				className="bg-cover mobile-show"
				autoPlay
				muted
				loop
				poster={image ? image.mediaItemUrl : ''}
				playsInline
			>
				{mobileMp4 && (
					<source src={mobileMp4.mediaItemUrl} type="video/mp4"></source>
				)}
				{mobileWebm && (
					<source src={mobileWebm.mediaItemUrl} type="video/webm"></source>
				)}
			</VideoWrapper>
		</>
	)
}

VideoPlayer.propTypes = {
	video: PropTypes.object,
	image: PropTypes.object,
	autoplay: PropTypes.bool,
}

VideoPlayer.defaultProps = {
	video: {},
	image: {},
	autoplay: true,
}

export default VideoPlayer

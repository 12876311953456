import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { PartnershipCard } from '../partnershipCard'
import { breakpoint, getColor } from '../../shared/theme'
import InnerWrapper from '../../elements/innerWrapper'

const ScrollWrap = styled.div`
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 100%;
	pointer-events: none;
`

const PartnershipsWrapper = styled.div`
	overflow: hidden;
	scrollbar-width: none;
	position: relative;
`

const PartnershipsInner = styled.div`
	overflow: auto;
	display: flex;
	align-items: flex-start;
	padding-bottom: 105px;
	cursor: grab;

	@media (${breakpoint('tablet')}) {
		padding-bottom: 155px;
	}

	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
		background: transparent
	}
`

const Spacer = styled.span`
	min-width: 4px;
	width: 4px;

	@media ${breakpoint('tablet')} {
		display: block;
		min-width: calc(11.77% + 20px);
		width: calc(11.77% + 20px);
	}
`

const ScrollTrack = styled.div`
	width: 100%;
	height: 4px;
	position: relative;
	pointer-events: none;
	/* cursor: grab; */

	&::before {
		content: '';
		top: 1px;
		left: 0;
		position: absolute;
		width: 100%;
		height: 1px;
		background: ${getColor('greyMid')};

		@media ${breakpoint('tablet')} {
			height: 2px;
			background: ${getColor('orange')};
		}
	}
`

const ScrollThumb = styled.div`
	height: 3px;
	width: 50%;
	background: ${getColor('black')};
	transform: translateX(${(props) => props.position}px);
	pointer-events: none;

	@media ${breakpoint('tablet')} {
		height: 4px;
	}
`

export const PartnerScroller = ({ partnerships, link }) => {
	const scrollBody = useRef(null)
	const scrollTrack = useRef(null)
	const scrollThumb = useRef(null)
	const slider = useRef(null)

	const [scrollPosition, setScroll] = useState(0)
	const [isDown, setDown] = useState(false)
	const [startX, setX] = useState(null)
	const [scrollLeft, setScrollLeft] = useState(null)
	const [isActive, setActive] = useState(false)

	useLayoutEffect(() => {
		const scroller = scrollBody.current
		const scrollbar = scrollTrack.current
		const thumb = scrollThumb.current

		function detectScroll() {
			setTimeout(() => {
				let width = scroller.clientWidth
				let scrollWidth = scroller.scrollWidth - width
				let currentScroll = scroller.scrollLeft
				let percent = currentScroll / scrollWidth

				let scrollbarWidth = scrollbar.clientWidth
				let thumbPos = scrollbarWidth * percent
				let thumbWidth = thumb.clientWidth

				let scrollValue =
					thumbPos <= scrollbarWidth - thumbWidth
						? thumbPos
						: scrollbarWidth - thumbWidth

				setScroll(scrollValue)
			}, 50)
		}

		scroller.addEventListener('scroll', detectScroll)

		return () => {
			scroller.removeEventListener('scroll', detectScroll)
		}
	}, [scrollPosition])

	const mousedown = (e) => {
		const sliderBody = scrollBody.current;

		setDown(true);
		setActive(true);
		
		setX(e.pageX - sliderBody.offsetLeft);
		setScrollLeft(sliderBody.scrollLeft)
	}

	const mouseleave = () => {
		setDown(false);
		setActive(false);
	}

	const mouseup = () => {
		setDown(false);
		setActive(false);
	}

	const mousemove = (e) => {
		const sliderBody = scrollBody.current;

		if(!isDown) return;
		e.preventDefault();

		const x = e.pageX - sliderBody.offsetLeft;
		const walk = Math.round(x - startX) * 1.5;
		
		sliderBody.scrollLeft = (scrollLeft - walk) * -1;
	}

	return (
		<PartnershipsWrapper>

			<PartnershipsInner ref={scrollBody}
				onMouseDown={mousedown}
				onMouseMove={mousemove}
				onMouseLeave={mouseleave}
				onMouseUp={mouseup}>
				<Spacer></Spacer>
				{partnerships
					? partnerships.map(function (el, index) {
							return <PartnershipCard partnership={el} key={index} path={link.url} />
					  })
					: null}
			</PartnershipsInner>

			<ScrollWrap>
				<InnerWrapper>
					<ScrollTrack ref={scrollTrack}>
						<ScrollThumb ref={scrollThumb} position={scrollPosition} />
					</ScrollTrack>
				</InnerWrapper>
			</ScrollWrap>
		</PartnershipsWrapper>
	)
}

PartnerScroller.propTypes = {
	partnerships: PropTypes.array,
}

PartnerScroller.defaultProps = {
	partnerships: [],
}

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import { Content } from '../../elements/wyswyg'
import { breakpoint, getColor } from '../../shared/theme'
import PlaceholderVideo from '../../video/home-hero.mp4'
import VideoPlayer from '../video'
import SectionTransition from '../../elements/activeSectionDefault'
import Grid from '../../elements/grid'

const HeroSection = styled.section`
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
`

const HeroSectionInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`

const ContentWrap = styled(Content)`
	grid-column: -1 / 1;
	text-align: center;
	color: ${getColor('white')};

	h1 {
		font-weight: 700;
	}

	@media ${breakpoint('tablet')} {
		grid-column: 2 / 14;
	}

	@media ${breakpoint('laptop')} {
		grid-column: 3 / 13;
	}

	@media ${breakpoint('desktop')} {
		grid-column: 2 / 14;
		padding: 0 1em;
	}
`

export const HeroVideo = ({ video, image, isActive, copy }) => {
	if (!video && !image) {
		return
	}

	return (
		<HeroSection>
			{video && image && <VideoPlayer video={video} image={image} />}

			<HeroSectionInner>
				<InnerWrapper>
					<Grid>
						<ContentWrap>
							<h1 className="header">
								{ copy && ( copy ) }
							</h1>
						</ContentWrap>
					</Grid>
				</InnerWrapper>
			</HeroSectionInner>
		</HeroSection>
	)
}

HeroVideo.propTypes = {
	vidoes: PropTypes.object,
	image: PropTypes.object,
	isActive: PropTypes.bool,
}

HeroVideo.defaultProps = {
	vidoes: {},
	image: {},
	isActive: false,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SwiperCore, {
	Navigation,
	EffectFade,
	Thumbs,
	Pagination,
	A11y,
	Autoplay,
} from 'swiper'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import { breakpoint, getColor } from '../../shared/theme'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ShowcaseSlide } from '../homeShowcaseSlide'
import { ShowcaseThumb } from '../homeShowcaseThumb'
import { HomeSectionHeader } from '../homeSectionHeader'
import SectionTransition from '../../elements/activeSectionDefault'

// Import Swiper styles
import swiperStyles from 'swiper/swiper.scss'
import swiperFade from 'swiper/components/effect-fade/effect-fade.scss'

const ShowcaseWrapper = styled.div`
	position: relative;
	padding-top: 40px;
	background: ${getColor('greyLight')};

	@media ${breakpoint('tablet')} {
		padding-top: 80px;
	}
`

const ShowcaseInner = styled(SectionTransition)``

const SliderNav = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-bottom: 30px;
	z-index: 2;
	pointer-events: none;

	@media ${breakpoint('tablet')} {
		padding-bottom: 54px;
		pointer-events: all;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.8) 0%,
			rgba(0, 0, 0, 0) 100%
		);
	}

	.swiper-slide-thumb-active {
		> * {
			opacity: 1;
		}

		.progress-bar {
			background: ${getColor('orange')};

			&::after {
				max-width: 100%;
				transition: max-width 4000ms linear;
			}
		}
	}

	.swiper-container {
		display: flex;
		flex-direction: column;
	}

	.swiper-pagination {
		order: 999;
		margin-top: 16px;
		display: block;

		span {
			width: 12px;
			height: 12px;
			margin-right: 16px;
			display: inline-block;
			transform: scale(0.666);
			border-radius: 50%;
			background: ${getColor('greyMid')};
			transition: all 200ms ${(props) => props.theme.ease};

			&:last-child {
				margin-right: 0;
			}

			&.swiper-pagination-bullet-active {
				transform: scale(1);
				background: ${getColor('white')};
			}
		}

		@media ${breakpoint('tablet')} {
			visibility: collapse;
		}
	}
`

SwiperCore.use([Navigation, EffectFade, Thumbs, Pagination, A11y, Autoplay])

export const Showcase = ({ isActive, gallery, subheading }) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null)

	if (!gallery) {
		return null
	}

	return (
		<ShowcaseWrapper>
			<ShowcaseInner isActive={isActive}>
				<HomeSectionHeader
					link={{ url: `/work`, title: `CLIENT SHOWCASE` }}
					isActive={isActive}
				/>

				<Swiper
					slidesPerView={1}
					loop
					speed={1200}
					autoplay={{
						delay: 4000,
					}}
					effect="fade"
					thumbs={{ swiper: thumbsSwiper }}
				>
					{gallery.map(function (el, index) {
						return (
							<SwiperSlide key={index}>
								<ShowcaseSlide title={el.heading} image={el.image} />
							</SwiperSlide>
						)
					})}
				</Swiper>

				<SliderNav>
					<InnerWrapper>
						<Swiper
							onSwiper={setThumbsSwiper}
							watchSlidesVisibility
							watchSlidesProgress
							slidesPerView={1}
							pagination={{ clickable: true }}
							spaceBetween={0}
							breakpoints={{
								0: {
									slidesPerView: 1,
								},
								640: {
									spaceBetween: 40,
									slidesPerView: 2,
								},
								1199: {
									spaceBetween: 40,
									slidesPerView: 4,
								},
							}}
						>
							{gallery.map(function (el, index) {
								return (
									<SwiperSlide key={index}>
										<ShowcaseThumb title={el.heading} copy={el.copy} />
									</SwiperSlide>
								)
							})}
						</Swiper>
					</InnerWrapper>
				</SliderNav>
			</ShowcaseInner>
		</ShowcaseWrapper>
	)
}

Showcase.propTypes = {
	isActive: PropTypes.bool,
	gallery: PropTypes.array,
}

Showcase.defaultProps = {
	isActive: PropTypes.false,
	gallery: [],
}

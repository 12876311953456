import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WpLink from '../../elements/wpLink'

import { breakpoint, getColor } from '../../shared/theme'

const Card = styled.div`
	width: 67.75%;
	min-width: 250px;
	padding: 0 16px;
	text-align: center;
	cursor: pointer;
	position: relative;

	@media ${breakpoint('laptop')} {
		width: 23%;
		min-width: 442px;
		padding: 0 40px;
	}

	h5 {
		font-size: 10px;
		text-transform: uppercase;
		line-height: 0.8;
		margin-bottom: 8px;
	}

	p {
		font-size: 14px;
		display: none;

		@media ${breakpoint('mobile')} {
			display: block;
		}
	}

	a
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
`

const ImageWrapper = styled.div`
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 64px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 40px;
	}

	img {
		pointer-events: none;
		object-fit: contain;
		object-position: center;
	}

	&::before {
		content: '';
		display: block;
		padding-top: 115%;
	}
`

const Detail = styled.span`
	color: ${getColor('orange')};
	border: 1px solid ${getColor('orange')};
	font-size: 10px;
	text-transform: uppercase;
	line-height: 0.8;
	display: inline-block;
	padding: 12px 24px;
	margin: 0 auto 24px 0;

	@media ${breakpoint('tablet')} {
		display: none;
	}
`

export const PartnershipCard = ({ partnership, path }) => {
	if (!partnership || partnership == null) {
		return
	}

	const { image, heading, subheading } = partnership

	return (
		<Card>
			<WpLink uri={path} title={subheading} />
			<ImageWrapper>
				{image && (
					<img src={image.mediaItemUrl} alt={heading} className="bg-cover" />
				)}
			</ImageWrapper>

			<h5>{subheading}</h5>
			<p>{heading}</p>
		</Card>
	)
}

PartnershipCard.propTypes = {
	partnership: PropTypes.object,
	path: PropTypes.string,
}

PartnershipCard.defaultProps = {
	partnership: null,
	path: '#',
}

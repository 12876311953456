import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import placeholder from '../../images/placeholder-square.jpg'
import { breakpoint, getColor } from '../../shared/theme'
import WpLink from '../../elements/wpLink'

const Card = styled.div`
	position: relative;
	width: 100%;
	margin-bottom: 2px;
	display: block;
	color: ${getColor('white')};

	@media ${breakpoint('tablet')} {
		width: calc(4 / 12 * 100% - (2px * 2 / 3));
		margin-bottom: 0;
	}

	&::before {
		content: '';
		display: block;
		position: relative;
		width: 100%;
		padding-top: 66.66%;
		height: 0;
		background: ${getColor('black')};
		z-index: -1;

		@media ${breakpoint('tablet')} {
			padding-top: 100%;
		}
	}

	&::last-child {
		margin: 0 0;
	}

	img {
		z-index: -1;
		opacity: 0.75;
	}
`

const CardInner = styled.div`
	z-index: 2;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 18px;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;

	@media ${breakpoint('tablet')} {
		padding: 24px;
		margin-bottom: 36px;
		border-bottom: 2px solid ${getColor('white')};
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;
	}

	@media ${breakpoint('desktop')} {
		padding: 36px;
		margin-bottom: 54px;
	}

	h1 {
		font-size: 80px;
		line-height: 1;
		font-weight: 700;
		margin-right: 20px;

		@media ${breakpoint('tablet')} {
			opacity: 0.5;
			line-height: 0.8;
		}

		@media ${breakpoint('desktop')} {
			font-size: 100px;
		}
	}

	p {
		font-size: 14px;
		line-height: 1.28;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0;

		> span {
			display: block;
			font: inherit;
		}
	}
`

const Overlay = styled.div`
	background: ${getColor('orange')};
	color: ${getColor('white')};
	display: flex;
	top: 0;
	left: 0;
	position: absolute;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 10;
	padding: 20px;
	opacity: 0;
	transition: opacity 400ms ${(prop) => prop.theme.ease};

	a {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	${Card}:hover & {
		opacity: 1;

		span {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@media ${breakpoint('tablet')} {
		padding: 48px;
	}

	@media ${breakpoint('desktop')} {
		padding: 60px;
	}

	span {
		display: block;
		transform: translateY(20px);
		opacity: 0;
		transition: all 400ms ${(prop) => prop.theme.ease};
	}
`

export const ProcessCard = ({ index, process, link }) => {
	const count = index + 1
	const [title, setHeading] = useState([])

	if (process == null) {
		return
	}

	const { heading, image } = process

	const titleArr = heading ? heading.split(' ') : []

	useEffect(() => {
		setHeading(titleArr)
	}, [])

	return (
		<Card>
			{image && (
				<img src={image.mediaItemUrl} alt={title} className="bg-cover" />
			)}
			<CardInner>
				<h1>{count}</h1>
				<p>
					{title && title.map((line, index) => <span key={index}>{line}</span>)}
				</p>
			</CardInner>

			{link && (
				<Overlay>
					<WpLink uri={link.url} title={title}>
						<h3>
							<span>Our</span>
							<span>Process</span>
						</h3>
					</WpLink>
				</Overlay>
			)}
		</Card>
	)
}

ProcessCard.propTypes = {
	index: PropTypes.number,
	process: PropTypes.object,
	link: PropTypes.object,
}

ProcessCard.defaultProps = {
	index: '',
	process: {
		image: {},
	},
	link: {
		url: '#',
		title: '',
	},
}

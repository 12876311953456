import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import { ProcessCard } from '../processCard'

const CardWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 20px;

	@media ${breakpoint('tablet')} {
		width: 100%;
	}

	@media ${breakpoint('laptop')} {
		margin-bottom: 0;
		width: calc(1610 / 1920 * 100%);
		margin-right: 16px;
	}

	&::after {
		content: '';
		width: calc(4 / 12 * 100% - (16px * 2 / 3));
		order: 999;
		display: block;
		position: relative;
		height: 0;
		opacity: 0;
	}
`

export const ProcessColumns = ({ processes, link }) => {
	return (
		<CardWrapper>
			{processes &&
				processes.map(function (process, index) {
					return (
						<ProcessCard
							key={index}
							process={process}
							index={index}
							link={link}
						/>
					)
				})}
		</CardWrapper>
	)
}

ProcessColumns.propTypes = {
	processes: PropTypes.array,
	link: PropTypes.object,
}

ProcessColumns.defaultProps = {
	processes: [],
	link: {},
}

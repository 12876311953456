import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { HomeSectionHeader } from '../homeSectionHeader'
import { ProcessColumns } from '../processColumns'
import { breakpoint, getColor } from '../../shared/theme'

import LinkButtonAlt from '../../elements/linkButtonAlt'
import SectionTransition from '../../elements/activeSectionDefault'

const ProcessWrapper = styled(SectionTransition)`
	padding-top: 60px;
	margin-bottom: 48px;

	@media (${breakpoint('tablet')}) {
		padding-top: 82px;
		margin-bottom: 180px;
	}
`

const ProcessBody = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const LearnMore = styled.span`
	padding-left: 20px;
	padding-right: 20px;
	font-size: 12px;
	line-height: 16px;
	display: inline-block;
	position: relative;

	@media ${breakpoint('tablet')} {
		align-self: flex-end;
		margin-bottom: 36px;
		padding-left: 36px;
		padding-right: 36px;
	}

	@media ${breakpoint('laptop')} {
		padding: 0 0;
	}

	@media ${breakpoint('desktop')} {
		margin-bottom: 54px;
	}
`

export const OurProcess = ({ isActive, subheading, processes, learnMore }) => {
	return (
		<ProcessWrapper isActive={isActive}>
			<HomeSectionHeader link={subheading} isActive={isActive} />

			{processes && (
				<ProcessBody>
					<ProcessColumns processes={processes} link={learnMore} />

					<LearnMore>
						<LinkButtonAlt
							path={learnMore.url}
							title={`Learn More`}
							target={learnMore.target}
						/>
					</LearnMore>
				</ProcessBody>
			)}
		</ProcessWrapper>
	)
}

OurProcess.propTypes = {
	isActive: PropTypes.bool,
	subheading: PropTypes.object,
	processes: PropTypes.array,
	learnMore: PropTypes.object,
}

OurProcess.defaultProps = {
	isActive: false,
	subheading: {},
	processes: [],
	learnMore: {},
}
